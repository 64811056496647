@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg-color: rgb(209, 23, 34);
  --main-bg-color-darker: rgb(174, 22, 31);
  --light-bg-color: #F5FAFD;
  --secondary-color: #eac224;
  --secondary-light-color: #03a9f4;
}

* {
  margin: 0;
  padding: 0;
  position: relative;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

#root {
  overflow-x: hidden;
}

*::before,
*::after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5 {
  letter-spacing: 1px;
}

p {
  text-align: justify;
}

.secondary-light-color {
  color: #03a9f4;
  color: var(--secondary-light-color);
}

img {
  display: block;
}

.image {
  position: relative;
}

body {
  font-size: 16px;
}

body h1 {
  position: relative;
  z-index: 10;
  color: #fff;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 2.375em;
  line-height: 1.26;
}

.site-header h1 {
  margin-bottom: 0;
}
.site-header .subheader {
  max-width: 600px;
  text-align: center;
  z-index: 9;
  color: #fff;
}

body h2 {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 2.625em;
  line-height: 1.143;
  color: rgb(23, 37, 42);
}

body h3 {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 2.25em;
  line-height: 1.25;
  color: rgb(23, 37, 42);
}

body h4,
.ant-divider-inner-text * {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.55;
  text-transform: uppercase;
  margin: 1.125em 0;
  color: rgb(23, 37, 42);
}

body .subtext {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.5;
  color: rgb(37, 41, 42);
  text-align: center;
}

li {
  font-family: "Open Sans";
  color: rgb(70, 112, 127);
  margin-left: 30px;
}

body p {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: rgb(70, 112, 127);
}

.ant-btn {
  text-transform: uppercase;
  padding: 15px 30px !important;
  height: auto !important;
}

.ant-btn.ant-btn-primary {
  background-color: rgb(209, 23, 34);
  background-color: var(--main-bg-color);
  border: 2px solid rgb(209, 23, 34);
  border: 2px solid var(--main-bg-color);
}
.ant-btn.ant-btn-primary:hover {
  background-color: rgb(174, 22, 31);
  background-color: var(--main-bg-color-darker);
  border: 2px solid rgb(174, 22, 31);
  border: 2px solid var(--main-bg-color-darker);
}

.ant-btn-background-ghost {
  border: 2px solid #fff;
}

.ant-btn.ant-btn-background-ghost:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.ant-layout {
  background-color: transparent;
}

.site-navigation {
  background-color: rgb(209, 23, 34);
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.small-header {
  background: rgb(174, 22, 31);
  background: var(--main-bg-color-darker);
  text-align: right;
  color: #fff;
  font-size: 1em;
  padding: 0 70px;
}

.logo {
  font-size: 1.5em;
  line-height: 2.5em;
}

.site-navigation h2,
.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-submenu {
  color: #fff;
}

.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: rgb(166, 220, 244) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: 4px solid rgb(166, 220, 244);
}

.ant-menu-title-content, .ant-menu-title-content a { display: inline-block; }

.ant-menu .fa-bars { font-size: 1.5em; }

.site-header {
  position: relative;
  width: 100%;
  color: #FFFFFF;
  background-color: rgb(209, 23, 34);
  background-position: 51.066042641705664% 60.50472693179154%;
  background-size: cover;
  background-image: url(https://studentite.com/wp/wp-content/uploads/2021/10/tassels-g351cbae15_1920.jpg);
  background-attachment: scroll;
  background-repeat: no-repeat;
  padding-top: 150px;
  padding-bottom: 50px;
}

.site-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, .5);
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.ant-menu {
  background-color: transparent;
  border-bottom: rgb(209, 23, 34);
  border-bottom: var(--main-bg-color);
  width: 50px;
  justify-content: flex-end;
}

.ant-menu-title-content a {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.section {
  margin: auto !important;
  padding: 50px 0;
}

.light-background {
  background-color: #F5FAFD;
  background-color: var(--light-bg-color);
}

.call-to-action {
  background-color: rgb(209, 23, 34);
  background-color: var(--main-bg-color);
  text-align: center;
  color: #fff;
}

.call-to-action h3 {
  color: #fff;
  margin: 0;
}

.call-to-action .ant-btn,
.secondary-btn {
  background-color: #eac224;
  background-color: var(--secondary-color);
  border: 0;
  padding: 15px 30px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  line-height: 1;
  letter-spacing: 1px;
  height: auto;
  transition: all 1s;
}

.call-to-action .ant-btn:hover,
.secondary-btn:hover {
  background-color: rgb(238, 164, 26);
  color: #fff;
}

@media (min-width: 576px) {
  .section {
    max-width: 540px;
  }
  body h1 {
    font-size: 3.375em;
  }
  .main-info .ant-card .ant-col:nth-child(odd) {
    border-right: 1px solid #f3f3f3;
  }
}

@media (max-width: 768px) {
  .call-to-action .ant-col {
    text-align: center;
    padding: 5px 0;
  }
  .small-header {
    display: none;
  }
}

@media (min-width: 768px) {
  .section {
    max-width: 720px;
  }
  .main-info {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  .main-info .section {
    padding: 0;
  }

  .overlap-cols .left-col {
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
  }

  .overlap-cols .right-col {
    padding: 5% 0 !important;
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  
  .main-info .ant-card .ant-col:not(:last-child) {
    border-right: 1px solid #f3f3f3;
  }

  .call-to-action {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .section {
    max-width: 960px;
  }
  .ant-menu {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .section {
    max-width: 1232px;
  }
}

.image .frame {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: -1;
  -webkit-transform: translateX(5%) translateY(-5%);
          transform: translateX(5%) translateY(-5%);
  -webkit-transform-origin: center center 0px;
          transform-origin: center center 0px;
  background-color: transparent;
  border: 10px solid #eac224;
  border: 10px solid var(--secondary-color);
}

.image .frame.top {
  z-index: 1;
  -webkit-transform: translateX(-5%) translateY(-5%);
          transform: translateX(-5%) translateY(-5%);
}

.image .frame.full {
  background-color: #eac224;
  background-color: var(--secondary-color);
}

.ant-card {
  box-shadow: 0px 4px 10px 2px rgb(74 74 74 / 30%);
}

.ant-card .anticon {
  color: #eac224;
  color: var(--secondary-color);
  font-size: 5em;
}

.ant-card .icon {
  font-size: 5em;
}

.icon.primary {
  color: rgb(209, 23, 34);
  color: var(--main-bg-color);
}

.icon.secondary {
  color: #03a9f4;
  color: var(--secondary-light-color);
}

.icon.round {
  font-size: 3em;
  border: 2px solid #03a9f4;
  border: 2px solid var(--secondary-light-color);
  border-radius: 100%;
  padding: 20px;
}

.hero {
  height: 70vh;
}

.hero .ant-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero .ant-btn {
  padding: 10px 30px !important;
}

.hero .section {
  text-align: center;
  justify-content: center;
  padding: 20vh 10% !important;
}

.ant-carousel .wrapper {
  height: 70vh;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background: rgb(209, 23, 34);
  background: var(--main-bg-color);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-info .ant-card .ant-col {
  text-align: center;
  padding: 20px !important;
}

.main-info p {
  text-align: center;
}

.main-info .ant-card .ant-col .icon {
  margin-bottom: .75em;
  font-size: 3.25em;
}

.main-info .ant-card-body .ant-col p { margin: 0; }
.overlap-cols {
  padding-top: 0;
}
.overlap-cols .section {
  align-items: stretch;
  padding-top: 0;
}

.stretched-cols {
  align-items: stretch;
}

.stretched-cols .ant-col > div {
  height: 100%;
}

.stretched-cols .ant-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.overlap-cols .right-col {
  width: 100%;
}

.overlap-cols .left-col {
  padding: 10% !important;
  background-color: #d11722;
}

.overlap-cols .left-col * {
  color: #fff;
}

.overlap-cols h2 {
  font-size: 2.25em;
  font-weight: 300;
}

.overlap-cols .background {
  width: 100%;
  height: 100%;
  min-height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-form {
  width: 100%;
}

.home-cards p {
  text-align: center;
}

.no-style,
.no-style:hover {
  color: unset;
}

